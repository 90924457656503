export const ADDFUNDS_CREATE_REQUEST = "ADDFUNDS_CREATE_REQUEST";
export const ADDFUNDS_CREATE_SUCCESS = "ADDFUNDS_CREATE_SUCCESS";
export const ADDFUNDS_CREATE_FAIL = "ADDFUNDS_CREATE_FAIL";
export const ADDFUNDS_CREATE_RESET = "ADDFUNDS_CREATE_RESET";


export const ADDFUNDS_LIST_MY_REQUEST = "ADDFUNDS_LIST_MY_REQUEST";
export const ADDFUNDS_LIST_MY_SUCCESS = "ADDFUNDS_LIST_MY_SUCCESS";
export const ADDFUNDS_LIST_MY_FAIL = "ADDFUNDS_LIST_MY_FAIL";
export const ADDFUNDS_LIST_MY_RESET = "ADDFUNDS_LIST_MY_RESET";


export const ADDFUNDS_GETLIST_MY_REQUEST = "ADDFUNDS_GETLIST_MY_REQUEST";
export const ADDFUNDS_GETLIST_MY_SUCCESS = "ADDFUNDS_GETLIST_MY_SUCCESS";
export const ADDFUNDS_GETLIST_MY_FAIL = "ADDFUNDS_GETLIST_MY_FAIL";
export const ADDFUNDS_GETLIST_MY_RESET = "ADDFUNDS_GETLIST_MY_RESET";
